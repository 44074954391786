<template>
  <div class="rootView">
    <el-header>
      <el-card>
        <el-row>
          <el-col>{{$t('flightTraining.record')}}(IMEI:&nbsp;{{deviceImei}})</el-col>
        </el-row>
      </el-card>
    </el-header>
    <el-main>
      <el-card>
        <el-table :data="flightTrainingList" :border="true" v-loading="showLoading">
          <el-table-column prop="index" :label="$t('common.sn')" width="80"/>
          <el-table-column prop="cst" :formatter="dateFormat" :label="$t('flightTraining.cst')" width="150"/>
          <el-table-column prop="standbyDatetime" :formatter="dateFormat" :label="$t('flightTraining.standbyDatetime')" width="150"/>
          <el-table-column prop="firstGpstime" :formatter="dateFormat" :label="$t('flightTraining.firstLocationTime')" width="150"/>
          <el-table-column prop="cstEnddatetime" :formatter="dateFormat" :label="$t('flightTraining.endTime')" width="150"/>
          <el-table-column prop="cstValidenddatetime" :formatter="dateFormat" :label="$t('flightTraining.validEndTime')" width="150"/>
          <el-table-column prop="logTime" :formatter="dateFormat" :label="$t('flightTraining.lastLogTime')" width="150"/>
          <el-table-column prop="positionCount" :label="$t('flightTraining.pointCount')" width="80"/>
          <el-table-column prop="positionValidcount" :label="$t('flightTraining.validPointCount')" width="80"/>
          <el-table-column prop="totalpositioningTime" :label="$t('flightTraining.totalLocationDuration')" width="120"/>
          <el-table-column prop="totalStandbyTime" :label="$t('flightTraining.totalStandbyDuration')" width="120"/>
          <el-table-column :label="$t('common.operation')" width="270">
            <template slot-scope="slot">
              <el-button type="primary" @click="clickTrajectory(slot.row)">{{$t('devicePage.historicalTrack')}}</el-button>
              <el-button type="warning" @click="clickEdit(slot)">{{$t('common.edit')}}</el-button>
              <el-button type="danger" @click="clickDelete(slot)">{{$t('common.delete')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50]"
          :disabled="paginationDisabled"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"/>
      </el-card>
    </el-main>
    <el-dialog
    :title="$t('common.reminder')"
    :visible.sync="showConfirmDialog"
    width="30%">
      <span>{{$t('devicePage.msg.deleteFlightTrainingPrompt')}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showConfirmDialog = false">{{$t('common.cancel')}}</el-button>
        <el-button type="danger" @click="confirmToDelete">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('flightTraining.tip.updateValidEndTime')"
      width="40%"
      :visible.sync="showTimePickerDialog">
      <el-date-picker
        v-model="modifyDate"
        :clearable="false"
        type="datetime"
        :placeholder="$t('flightTraining.tip.selectValidEndTime')">
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showTimePickerDialog = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="confirmToUpdateValidEndTime">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment'
import { queryFlightTrainingList, deleteFlightTraining, updateFlightTrainingValidEndTime } from '@/api/gpsDevice'
import { getErrroDescStr } from '../../api/enum'
export default {
  data () {
    return {
      flightTrainingList: [],
      deviceImei: undefined,
      showLoading: true,
      showConfirmDialog: false,
      showTimePickerDialog: false,
      currentRecordObj: undefined,
      currentRecordIndex: undefined,
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      paginationDisabled: true,
      modifyDate: undefined
    }
  },
  created () {
    this.$i18n.locale = sessionStorage.getItem('lang')
    const beforeUnload = function () {
      sessionStorage.removeItem('startTime')
      sessionStorage.removeItem('endTime')
      sessionStorage.removeItem('historicalTrackDeviceImei')
      window.removeEventListener('beforeunload', beforeUnload)
    }
    window.addEventListener('beforeunload', beforeUnload)
  },
  mounted () {
    const imei = sessionStorage.getItem('deviceImei')
    if (imei) {
      this.deviceImei = imei
      this.getFlightTrainingListByImei(imei, this.currentPage, this.pageSize)
    }
  },
  methods: {
    handleSizeChange (val) {
      this.pageSize = val
      if (this.deviceImei) {
        this.showLoading = true
        this.paginationDisabled = true
        this.getFlightTrainingListByImei(this.deviceImei, this.currentPage, this.pageSize)
      }
    },
    handleCurrentChange (val) {
      this.currentPage = val
      if (this.deviceImei) {
        this.showLoading = true
        this.paginationDisabled = true
        this.getFlightTrainingListByImei(this.deviceImei, this.currentPage, this.pageSize)
      }
    },
    getFlightTrainingListByImei (imei, index, pageSize) {
      queryFlightTrainingList(imei, index, pageSize).then(res => {
        this.flightTrainingList = []
        this.showLoading = false
        this.paginationDisabled = false
        if (res.data.code === 0) {
          if (res.data.totalcount) {
            this.totalCount = parseInt(res.data.totalcount)
          }
          if (res.data.data && res.data.data[0]) {
            let index
            res.data.data[0].forEach(item => {
              if (!index) {
                if (this.flightTrainingList.length === 0) {
                  index = 1 + (this.currentPage - 1) * this.pageSize
                } else {
                  index = this.flightTrainingList.slice(-1).index + 1
                }
              } else {
                index += 1
              }
              item.index = index
              this.flightTrainingList.push(item)
            })
          }
        }
      }).catch(e => {
        this.paginationDisabled = false
        this.showLoading = false
      })
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue) {
        return moment(cellValue).format('yyyy-MM-DD HH:mm:ss')
      }
    },
    clickEdit (data) {
      this.showTimePickerDialog = true
      this.modifyDate = data.row.cstValidenddatetime
      this.currentRecordObj = data.row
      this.currentRecordIndex = data.$index
    },
    confirmToUpdateValidEndTime () {
      const id = this.currentRecordObj.id
      const index = this.currentRecordIndex
      const dateTime = this.modifyDate
      const dateStr = moment(this.modifyDate).format('yyyy-MM-DD HH:mm:ss')
      this.showTimePickerDialog = false
      this.modifyDate = undefined
      this.currentRecordIndex = undefined
      this.currentRecordObj = undefined
      updateFlightTrainingValidEndTime(id, dateStr).then(res => {
        if (res.data.code === 0) {
          this.flightTrainingList[index].cstValidenddatetime = dateTime
          this.$message.success(this.$t('common.updateSuccess'))
        } else {
          this.$message.error(this.$t('common.updateFailed'))
        }
      })
    },
    clickDelete (data) {
      this.showConfirmDialog = true
      this.currentRecordIndex = data.$index
      this.currentRecordObj = data.row
    },
    confirmToDelete () {
      this.showConfirmDialog = false
      if (this.currentRecordIndex && this.currentRecordObj) {
        this.showLoading = true
        deleteFlightTraining(this.currentRecordObj.id).then(res => {
          this.showLoading = false
          if (res.data.code === 0) {
            this.$message.success(this.$t('common.deleteSuccess'))
            this.flightTrainingList.splice(this.currentRecordIndex, 1)
          } else {
            this.$message.success(getErrroDescStr(res.data.code))
          }
          this.currentRecordIndex = undefined
          this.currentRecordObj = undefined
        })
      }
    },
    clickTrajectory (data) {
      sessionStorage.setItem('startTime', data.cst)
      sessionStorage.setItem('endTime', data.cstValidenddatetime)
      sessionStorage.setItem('historicalTrackDeviceImei', this.deviceImei)
      window.open(this.$router.resolve({ path: '/device/historicalTrack' }).href, '')
    }
  }
}
</script>
<style scoped>
</style>
