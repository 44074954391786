import request from '@/api/request'

export function getGpsDeviceById (deviceId) {
  return request({
    url: 'gps/device/getGpsDeviceByDeviceId/' + deviceId,
    method: 'get'
  })
}

export function getGpsDeviceByImei (deviceImei) {
  const req = {
    deviceImei
  }
  return request({
    url: 'app/device/getSingleDevice',
    method: 'post',
    data: req
  })
}

export function listGpsDevice () {
  return request({
    url: 'app/device/listDevice',
    method: 'get'
  })
}

export function deleteGpsDevice (deviceId) {
  const data = {
    deviceId: deviceId
  }
  return request({
    url: 'gps/device/deleteGpsDevice',
    method: 'post',
    data: data
  })
}

export function updateGpsDevice (data) {
  const req = {
    deviceId: data.deviceId,
    deviceImei: data.deviceImei,
    deviceName: data.deviceName,
    deviceType: data.deviceType,
    deviceRemark: data.deviceRemark,
    timeZone: data.timeZone,
    baseMileage: data.baseMileage,
    deptId: data.deptId,
    bindMobile: data.bindMobile,
    expireDate: data.expireDate,
    deviceStatus: data.deviceStatus
  }
  return request({
    url: 'gps/device/updateGpsDevice',
    method: 'post',
    data: req
  })
}

export function insertGpsDevice (data) {
  const req = {
    deviceImei: data.deviceImei,
    deviceName: data.deviceName,
    deviceType: data.deviceType,
    deviceRemark: data.deviceRemark,
    timeZone: data.timeZone,
    baseMileage: data.baseMileage,
    deptId: data.deptId,
    bindMobile: data.bindMobile,
    expireDate: data.expireDate
  }
  return request({
    url: 'gps/device/insertGpsDevice',
    method: 'post',
    data: req
  })
}

export function listGpsDeviceByMenu () {
  return request({
    url: 'gps/device/listGpsDeviceByMenu',
    method: 'get'
  })
}

export function listAllGpsDevice () {
  return request({
    url: 'app/device/listDevice',
    method: 'get'
  })
}

export function queryAllDealers (data) {
  const req = {
    action: 'userAction',
    // method: 'queryAllByAgent',
    method: 'queryAllUserBindDevice',
    parameter: data
  }
  return request({
    url: 'MainServlet',
    method: 'post',
    data: req
  })
}

export function configQueryAllDealers (data) {
  const req = {
    action: 'deviceAction',
    // method: 'queryAllByAgent',
    method: 'selectdeviceOnlineList',
    parameter: data
  }
  return request({
    url: 'MainServlet',
    method: 'post',
    data: req
  })
}

export function lastDevicePosition (data) {
  const req = {
    deviceImei: data
  }
  return request({
    url: 'app/device/getSingleDevice',
    method: 'post',
    data: req
  })
}

export function getDeviceTankByDeviceId (deviceId) {
  return request({
    url: 'gps/device/getDeviceTankByDeviceId/' + deviceId,
    method: 'get'
  })
}

export function updateDeviceTankConfig (data) {
  const req = {
    deviceId: data.deviceId,
    ad1Max: data.ad1Max,
    ad1Min: data.ad1Min,
    ad2Max: data.ad2Max,
    ad2Min: data.ad2Min,
    ad3Max: data.ad3Max,
    ad3Min: data.ad3Min,
    ad4Max: data.ad4Max,
    ad4Min: data.ad4Min
  }
  return request({
    url: 'gps/device/updateDeviceTankConfig',
    method: 'post',
    data: req
  })
}

export function getDeviceConfiguration (imei) {
  const req = {
    action: 'deviceRaceconfigAction',
    method: 'query',
    parameter: imei
  }
  return request({
    url: '/MainServlet',
    method: 'post',
    data: req
  })
}

export function updateDeviceConfiguration (imei, configuration) {
  const req = {
    action: 'deviceRaceconfigAction',
    method: 'update',
    parameter: imei,
    data: configuration
  }
  return request({
    url: '/MainServlet',
    method: 'post',
    data: req
  })
}

export function queryFlightTrainingList (imei, pageIndex, pageSize) {
  const req = {
    action: 'deviceRaceConfigPlanAction',
    method: 'query',
    parameter: imei,
    pageIndex: pageIndex,
    pageSize: pageSize
  }
  return request({
    url: '/MainServlet',
    method: 'post',
    data: req,
    timeout: 30000
  })
}

export function deleteFlightTraining (id) {
  const req = {
    action: 'deviceRaceConfigPlanAction',
    method: 'del',
    parameter: id
  }
  return request({
    url: '/MainServlet',
    method: 'post',
    data: req
  })
}

export function updateFlightTrainingValidEndTime (id, dateStr) {
  const req = {
    action: 'deviceRaceConfigPlanAction',
    method: 'update',
    data: {
      id: id,
      cstValidenddatetime: dateStr
    }
  }
  return request({
    url: '/MainServlet',
    method: 'post',
    data: req
  })
}

/**
 *  获取多个设备轨迹数据
 * @param {*} jsonArray 多个设备的数据
 */
export function getMultipleDeviceTraceLog (jsonArray) {
  const req = {
    requestList: jsonArray
  }
  return request({
    // TODO: 获取多个设备轨迹数据
    // url: 'app/gps/location/getMultipleDeviceLastTraceLog', //  获取设备最后一个点的数据  getSelectDateMultipleDeviceTraceLog  gpsLocationTime
    url: 'app/gps/location/getSelectDateMultipleDeviceTraceLog',
    method: 'post',
    data: req
  })
}